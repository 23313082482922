import React from 'react'
import HomeVideoWebm from '../../videos/home-video.webm'
import HomeVideoMp4 from '../../videos/home-video.mp4'
import HomeVideoOgv from '../../videos/home-video.ogv'
import HomeVideoPoster from '../../images/home-1.jpg'
import clsx from 'clsx'
import { motion } from 'framer-motion'

const HomeVideoSection = function HomeVideoSection({
  className,
  title,
  tintColor,
}) {
  return (
    <section className={clsx('h-screen select-none', className)}>
      <div className="video-container">
        <video id="grizzli-video" autoPlay muted loop poster={HomeVideoPoster}>
          <source src={HomeVideoWebm} type="video/webm" />
          <source src={HomeVideoMp4} type="video/mp4" />
          <source src={HomeVideoOgv} type="video/ogg" />
        </video>
      </div>
      <div className="video-overlay" style={{ backgroundColor: tintColor ?? '#011019' }} />
      <div className="container mx-auto flex items-center justify-center h-full">
        <motion.span
          initial={{ opacity: 0, y: -50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.6 },
          }}
          className="notera text-white text-4xl md:text-6xl above-video"
        >
          {title}
        </motion.span>
      </div>
    </section>
  )
}

export default HomeVideoSection
