import React from 'react'
import clsx from 'clsx'
import Image from 'gatsby-image'
import ResolvingLink from '../ResolvingLink'

const ToursSections = function ToursSections({ className, items = [] }) {
  return (
    <section
      className={clsx(
        'tours-cards relative container mx-auto grid grid-cols-1 px-4 md:px-0 md:grid-cols-2 gap-x-4 gap-y-4',
        className
      )}
    >
      {items.map((item, idx) => {
        const {
          title,
          subtitle,
          description,
          buttonText,
          buttonLink,
          imageAltText,
          fluidImage,
        } = item

        const isButtonValid = buttonText && buttonLink.url

        return (
          <div
            key={idx}
            className="bg-white border-8 border-black flex flex-col items-center pt-8"
          >
            <div className="flex-grow flex flex-col items-center justify-between px-8">
              <div className="tours-card-title">
                {title != null && title !== '' && (
                  <h1 className="text-gray-title text-center">{title}</h1>
                )}
                {subtitle != null && subtitle !== '' && (
                  <p className="text-yellow text-center">
                    <i>{subtitle}</i>
                  </p>
                )}
              </div>
              {description != null && description !== '' && (
                <p className="text-gray-body text-center">{description}</p>
              )}
              {isButtonValid && (
                <ResolvingLink
                  link={buttonLink}
                  className="btn btn-yellow-outline mt-8 uppercase"
                >
                  {buttonText}
                </ResolvingLink>
              )}
            </div>
            {fluidImage != null && (
              <Image
                className="mt-12 object-cover w-full"
                fluid={fluidImage}
                alt={imageAltText}
              />
            )}
          </div>
        )
      })}
    </section>
  )
}

export default ToursSections
