import React from 'react'
import clsx from 'clsx'
import BackgroundImage from 'gatsby-background-image-es5'
import { motion } from 'framer-motion'

const BannerSection = function BannerSection({
  className,
  title,
  fluidImg,
  isBlackText = false,
}) {
  const fluidImgStack = [
    'linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%)',
    fluidImg,
  ]

  return (
    <BackgroundImage
      Tag="section"
      className={clsx('hero flex items-center select-none', className)}
      fluid={fluidImgStack}
    >
      <div className="container mx-auto flex justify-center">
        <motion.span
          initial={{ opacity: 0, y: -50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.6 },
          }}
          className={clsx(
            'notera text-4xl md:text-6xl',
            isBlackText ? 'text-black' : 'text-white'
          )}
        >
          {title}
        </motion.span>
      </div>
    </BackgroundImage>
  )
}

export default BannerSection
