import React, { useEffect } from 'react'
import clsx from 'clsx'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const imgVariants = {
  initial: { opacity: 0 },
  start: {
    opacity: 1,
    transition: { duration: 0.6 },
  },
}

const descriptionVariants = {
  initial: { opacity: 0, y: 50 },
  start: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.35, duration: 0.6 },
  },
}

const FeaturedArtist = function FeaturedArtist({
  className,
  imageBuilder,
  titleBuilder,
  descriptionBuilder,
}) {
  const controls = useAnimation()
  const { ref, inView } = useInView({ threshold: 0.3 })

  useEffect(() => {
    if (inView) {
      controls.start('start')
    }
  }, [controls, inView])

  return (
    <section
      ref={ref}
      className={clsx('flex flex-col md:flex-row lg:items-center', className)}
    >
      <motion.div
        initial="initial"
        animate={controls}
        variants={imgVariants}
        className="self-stretch object-cover mx-4 md:mx-0 md:w-3/5"
      >
        {imageBuilder != null && imageBuilder('w-full h-full')}
      </motion.div>
      <motion.div
        initial="initial"
        animate={controls}
        variants={descriptionVariants}
        className="mx-4 px-12 py-16 flex flex-col items-start bg-black md:w-2/5 md:mx-0 md:py-32"
      >
        {titleBuilder != null && titleBuilder()}
        {descriptionBuilder != null && descriptionBuilder()}
      </motion.div>
    </section>
  )
}

export default FeaturedArtist
