import Image from 'gatsby-image'
import clsx from 'clsx'
import ResolvingLink from '../ResolvingLink'
import React from 'react'
import isButtonValid from '../../utils/isButtonValid'

export default function PostListItem({
  image,
  date,
  title,
  description,
  button,
  className,
}) {
  const buttonValid = isButtonValid(button.text, button.link?.url)

  return (
    <div
      className={clsx(
        'flex flex-col md:flex-row justify-start gap-6 py-12 md:gap-32 items-center',
        className
      )}
    >
      <Image
        className="post-img object-cover"
        alt={image.alt}
        fluid={image.fluidImage}
      />
      <div className="flex flex-col items-start post-description">
        <h2 className="text-gray-body">{date}</h2>
        <h1 className="mt-4 text-gray-title">{title}</h1>
        <div className={clsx('description-rich rich-gray')}>
          <p>{description}</p>
        </div>
        {buttonValid && (
          <ResolvingLink
            className="mt-8 btn btn-yellow-outline uppercase"
            link={button.link}
          >
            {button.text}
          </ResolvingLink>
        )}
      </div>
    </div>
  )
}
