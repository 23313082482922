import React from 'react'
import clsx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'
import getFluidImg from '../../utils/getFluidImg'
import Image from 'gatsby-image'
import moment from 'moment'
import ResolvingLink from '../ResolvingLink'
import PostListItem from '../shared/PostListItem'
import { RiCalendar2Fill } from 'react-icons/ri'
import { MdPersonOutline } from 'react-icons/md'

function BlogPostGridItem({ post, className }) {
  const { title, date, image, link, writer } = post

  return (
    <div className={clsx('flex flex-col shadow-lg', className)}>
      <Image
        className="blog-post-grid-img"
        alt={image.alt}
        fluid={image.fluidImage}
      />
      <div className="flex flex-col items-start flex-grow pt-2 px-8 pb-8">
        <div className="flex self-stretch justify-between text-sm">
          <div className="flex items-center gap-2">
            <RiCalendar2Fill className="text-yellow" size={20} />
            <span>{date}</span>
          </div>
          <div className="flex items-center gap-2">
            <MdPersonOutline className="text-yellow" size={20} />
            <span>{writer}</span>
          </div>
        </div>
        <h1 className="mt-4 text-gray-title mb-8 text-3xl">{title}</h1>
        {link && link.url && (
          <ResolvingLink link={link} className="mt-auto text-yellow hover:text-black">
            Read more
          </ResolvingLink>
        )}
      </div>
    </div>
  )
}

function LatestBlogPost({ post, className }) {
  const { title, description, image, link } = post

  return (
    <section
      className={clsx('container mx-auto px-4 md:px-0', className)}
    >
      <PostListItem
        button={{ text: 'READ MORE', link: link }}
        date="Latest Entry"
        title={title}
        image={image}
        description={description}
        className="blog-post-featured-post"
      />
    </section>
  )
}

function BlogPostsGrid({ posts, className }) {
  return (
    <section
      className={clsx(
        'container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8',
        className
      )}
    >
      {posts.map(post => {
        return <BlogPostGridItem post={post} />
      })}
    </section>
  )
}

export default function BlogPostsSlice() {
  const data = useStaticQuery(graphql`
    {
      posts: allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            id
            data {
              post_title {
                text
              }
              date
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              short_description {
                text
              }
              writer_name {
                text
              }
              link {
                url
                link_type
                target
              }
            }
          }
        }
      }
    }
  `)

  const posts = data?.posts?.edges ?? []

  const mappedPosts = posts.map(rawPost => {
    const node = rawPost?.node
    const id = node?.id
    const title = node?.data?.post_title?.text
    const date = node?.data?.date
    const rawImage = node?.data?.image
    const description = node?.data?.short_description?.text
    const link = node?.data?.link
    const writer = node?.data?.writer_name?.text

    const image = {
      alt: rawImage?.alt,
      fluidImage: getFluidImg(rawImage),
    }

    let formattedDate = date != null && date !== '' ? moment(date) : null
    formattedDate = formattedDate?.format('D MMM YYYY')?.toUpperCase()

    return {
      id,
      title,
      date: formattedDate,
      image,
      description,
      link,
      writer,
    }
  })

  return (
    <>
      {mappedPosts.length > 0 && <LatestBlogPost post={mappedPosts[0]} />}
      <BlogPostsGrid posts={mappedPosts} className="mt-12 px-4 md:px-0" />
    </>
  )
}
