import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import DetailSection from '../components/sections/DetailSection'
import Image from 'gatsby-image'
import HomeVideoSection from '../components/sections/HomeVideoSection'
import clsx from 'clsx'
import BannerSection from '../components/sections/BannerSection'
import FeaturedArtist from '../components/sections/FeaturedArtist'
import DonationSection from '../components/sections/DonationSection'
import ToursSections from '../components/sections/ToursSections'
import ResolvingLink from '../components/ResolvingLink'
import getFluidImg from '../utils/getFluidImg'
import isButtonValid from '../utils/isButtonValid'
import PageLayout from '../components/PageLayout'
import RichText from 'prismic-reactjs/src/Component'
import EndingSectionSlice from '../components/slices/EndingSectionSlice'
import SEO from '../components/seo'
import ArtGalleryPostsSlice from '../components/slices/ArtGalleryPostsSlice'
import BlogPostsSlice from '../components/slices/BlogPostsSlice'
import { getAlignmentTextClass } from '../utils/getAlignment'
import genericHtmlSerializer from '../utils/genericHtmlSerializer'

export const query = graphql`
  query PageQuery($uid: String, $lang: String) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      data {
        page_name {
          text
        }
        page_description {
          text
        }
        body {
          __typename
          ... on PrismicPageBodyHomeVideoSection {
            id
            slice_label
            slice_type
            primary {
              centered_title {
                text
              }
              tint_color
            }
          }
          ... on PrismicPageBodyBanner {
            id
            slice_label
            slice_type
            primary {
              centered_text {
                text
              }
              is_black_text
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyDetailSection1 {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              button_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              is_reversed
            }
          }
          ... on PrismicPageBodyToursSections {
            id
            slice_label
            slice_type
            items {
              button_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
              description {
                text
              }
              subtitle {
                text
              }
              title {
                text
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyDonationSection {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            items {
              button_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
              single_description {
                text
              }
              single_title {
                text
              }
            }
          }
          ... on PrismicPageBodyFeaturedArtistSection {
            id
            slice_label
            slice_type
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTitle {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
              alignment
            }
          }
          ... on PrismicPageBodyText {
            id
            slice_label
            slice_type
            primary {
              text {
                text
                html
                raw
              }
              alignment
            }
          }
          ... on PrismicPageBodyButton {
            id
            slice_label
            slice_type
            primary {
              text {
                text
              }
              link {
                url
                link_type
                target
              }
              alignment
            }
          }
          ... on PrismicPageBodyArtGalleryPosts {
            id
            slice_label
            slice_type
          }
          ... on PrismicPageBodyBlogPosts {
            id
            slice_label
            slice_type
          }
          ... on PrismicPageBodyDetailSection {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
              description {
                text
                html
                raw
              }
              button_text {
                text
              }
              bottom_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              is_reversed
              is_overlapping_previous_section
              is_dark
            }
          }
        }
      }
    }
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

const FeaturedArtistSlice = function FeaturedArtistSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      title: { text: titleText },
      description: { text: descriptionText },
      image,
    },
  } = slice

  const { alt: imageAltText } = image
  const fluidImage = getFluidImg(image)

  return (
    <FeaturedArtist
      className="mt-12"
      imageBuilder={
        fluidImage != null
          ? className => {
              return (
                <Image
                  className={className}
                  fluid={fluidImage}
                  alt={imageAltText}
                />
              )
            }
          : null
      }
      titleBuilder={
        titleText != null && titleText !== ''
          ? () => {
              return <h1 className="text-white">{titleText}</h1>
            }
          : null
      }
      descriptionBuilder={
        descriptionText != null && descriptionText !== ''
          ? () => {
              return <p className="mt-8 text-white">{descriptionText}</p>
            }
          : null
      }
    />
  )
}

const DonationSectionSlice = function DonationSectionSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: { image },
    items,
  } = slice

  const { alt: imageAltText } = image
  const fluidImage = getFluidImg(image)

  if (items == null || items.length === 0) {
    return null
  }

  const donationItems = items.map(item => {
    const {
      single_title: { text: title },
      single_description: { text: description },
      button_text: { text: buttonText },
      button_link: buttonLink,
    } = item

    return {
      title,
      description,
      buttonText,
      buttonLink,
    }
  })

  return (
    <DonationSection
      className="mt-12"
      items={donationItems}
      imageBuilder={
        fluidImage != null
          ? className => {
              return (
                <Image
                  className={className}
                  fluid={fluidImage}
                  alt={imageAltText}
                />
              )
            }
          : null
      }
      itemBuilder={(item, idx) => {
        const { title, description, buttonText, buttonLink } = item

        const buttonValid = isButtonValid(buttonText, buttonLink.url)

        return (
          <div key={idx} className="flex flex-col items-start">
            {title != null && title !== '' && <h1>{title}</h1>}
            {description != null && description !== '' && (
              <p className="mt-8">{description}</p>
            )}
            {buttonValid && (
              <ResolvingLink
                className="mt-8 btn btn-white-outline uppercase"
                link={buttonLink}
              >
                {buttonText}
              </ResolvingLink>
            )}
          </div>
        )
      }}
    />
  )
}

const ToursSectionsSlice = function ToursSectionsSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const { items } = slice

  if (items == null || items.length === 0) {
    return null
  }

  const tourItems = items.map(item => {
    const {
      title: { text: title },
      subtitle: { text: subtitle },
      description: { text: description },
      button_text: { text: buttonText },
      button_link: buttonLink,
      image,
    } = item

    const { alt: imageAltText } = image
    const fluidImage = getFluidImg(image)

    return {
      title,
      subtitle,
      description,
      buttonText,
      buttonLink,
      imageAltText,
      fluidImage,
    }
  })

  return <ToursSections className="-mt-12" items={tourItems} />
}

const DetailSectionSlice = function DetailSectionSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      title: { text: titleText },
      description,
      button_text: { text: buttonText },
      bottom_text: { text: bottomText },
      // TODO - Button link is empty if not defined.
      button_link: buttonLink,
      is_reversed: isReversed,
      is_overlapping_previous_section: isOverlappingPreviousSection,
      image,
      is_dark: isDark,
    },
  } = slice

  // TODO - Check if button is valid
  const isButtonValid = !(
    buttonLink.url == null ||
    buttonLink.url === '' ||
    buttonText == null ||
    buttonText === ''
  )

  const { alt: imageAltText } = image
  const fluidImage = getFluidImg(image)

  const sectionClassName = isOverlappingPreviousSection ? '-mt-12' : 'mt-12'

  // TODO - Check if image is valid
  const section = (
    <DetailSection
      isReversed={isReversed}
      isOverlappingPreviousSection={isOverlappingPreviousSection}
      className={clsx(!isDark && sectionClassName)}
      imgBuilder={
        fluidImage != null
          ? className => {
              return (
                <Image
                  className={className}
                  fluid={fluidImage}
                  alt={imageAltText}
                />
              )
            }
          : null
      }
      titleBuilder={
        titleText != null && titleText !== ''
          ? () => {
              return (
                <h1 className={isDark ? 'text-white' : 'text-gray-title'}>
                  {titleText}
                </h1>
              )
            }
          : null
      }
      descriptionBuilder={
        description.raw != null && description.raw.length !== 0
          ? () => {
              return (
                <div
                  className={clsx(
                    'description-rich',
                    isDark ? 'rich-white' : 'rich-gray'
                  )}
                >
                  <RichText render={description.raw} />
                </div>
              )
            }
          : null
      }
      buttonBuilder={
        isButtonValid
          ? () => {
              return (
                <ResolvingLink
                  link={buttonLink}
                  className={clsx(
                    'mt-8 btn uppercase focus:outline-none',
                    isDark ? 'btn-white-outline' : 'btn-yellow-outline'
                  )}
                >
                  {buttonText}
                </ResolvingLink>
              )
            }
          : null
      }
      bottomBuilder={
        bottomText != null && bottomText !== ''
          ? () => {
              return (
                <p
                  className={clsx(
                    'mx-12 mt-12',
                    isDark ? 'text-white' : 'text-gray-body'
                  )}
                >
                  {bottomText}
                </p>
              )
            }
          : null
      }
    />
  )

  if (isDark) {
    return (
      <div className={clsx('bg-black pb-40', sectionClassName)}>{section}</div>
    )
  }

  return section
}

const HomeVideoSectionSlice = function HomeVideoSectionSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      centered_title: { text: titleText },
      tint_color: tintColor,
    },
  } = slice

  return <HomeVideoSection title={titleText} tintColor={tintColor} />
}

const BannerSectionSlice = function BannerSectionSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      centered_text: { text: titleText },
      is_black_text: isBlackText,
      image,
    },
  } = slice

  const fluidImage = getFluidImg(image)

  if (fluidImage == null) {
    return null
  }

  return (
    <BannerSection
      title={titleText}
      fluidImg={fluidImage}
      isBlackText={isBlackText}
    />
  )
}

const Title = ({ slice }) => {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      title: { text: titleText },
      alignment,
    },
  } = slice

  return (
    <section
      className={clsx(
        'container mx-auto text-gray-title my-8 px-4 md:px-0',
        getAlignmentTextClass(alignment)
      )}
    >
      <h1>{titleText}</h1>
    </section>
  )
}


const Text = ({ slice }) => {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      text: { raw: rawDescription },
      alignment,
    },
  } = slice

  return (
    <section
      className={clsx(
        'container mx-auto my-8 description-rich rich-gray px-4 md:px-0',
        getAlignmentTextClass(alignment)
      )}
    >
      <RichText render={rawDescription} htmlSerializer={genericHtmlSerializer} />
    </section>
  )
}

const Button = ({ slice }) => {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      text: { text: buttonText },
      link,
      alignment,
    },
  } = slice

  const isValidButton = isButtonValid(buttonText, link?.url);

  if (!isValidButton) {
    return null;
  }

  return (
    <section
      className={clsx(
        'container mx-auto my-8 px-4 md:px-0',
        getAlignmentTextClass(alignment)
      )}
    >
      <ResolvingLink
        link={link}
        className={clsx(
          'mt-8 btn uppercase focus:outline-none btn-yellow-outline',
        )}
      >
        {buttonText}
      </ResolvingLink>
    </section>
  )
}

const PageSlices = function PageSlices({ slices }) {
  if (slices == null || slices.length === 0) {
    return []
  }

  const renderedSlices = slices.map(slice => {
    const { slice_type: sliceType, id } = slice
    switch (sliceType) {
      case 'home_video_section':
        return <HomeVideoSectionSlice key={id} slice={slice} />
      case 'banner':
        return <BannerSectionSlice key={id} slice={slice} />
      case 'detail_section':
        return <DetailSectionSlice key={id} slice={slice} />
      case 'detail_section1':
        // Ending Section
        return <EndingSectionSlice key={id} slice={slice} />
      case 'featured_artist_section':
        return <FeaturedArtistSlice key={id} slice={slice} />
      case 'donation_section':
        return <DonationSectionSlice key={id} slice={slice} />
      case 'tours_sections':
        return <ToursSectionsSlice key={id} slice={slice} />
      case 'art_gallery_posts':
        return <ArtGalleryPostsSlice key={id} />
      case 'blog_posts':
        return <BlogPostsSlice key={id} />
      case 'title':
        return <Title key={id} slice={slice} />
      case 'text':
        return <Text key={id} slice={slice} />
      case 'button':
        return <Button key={id} slice={slice} />
      default:
        return null
    }
  })

  return _.compact(renderedSlices)
}

export default function Page({ data, ...otherProps }) {
  const { prismicPage: singlePage } = data

  if (
    singlePage == null ||
    singlePage.data == null ||
    singlePage.data.body == null
  ) {
    // TODO - Generate 404
    return null
  }

  const pageTitle = _.get(singlePage, 'data.page_name.text')
  const pageDescription = _.get(singlePage, 'data.page_description.text')

  return (
    <PageLayout pageData={data}>
      <SEO title={pageTitle} description={pageDescription} />
      <PageSlices slices={singlePage.data.body} />
    </PageLayout>
  )
}
