import React, { useEffect } from 'react'
import clsx from 'clsx'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export const DetailImg = function DetailImg({ className, alt, ...props }) {
  return (
    <img
      {...props}
      alt={alt}
      className={clsx('object-cover px-4 md:w-3/5 md:px-0', className)}
    />
  )
}

const variants = {
  initial: { opacity: 0, y: 50 },
  start: delayIdx => ({
    y: 0,
    opacity: 1,
    transition: { delay: (delayIdx ?? 0) * 0.35, duration: 0.6 },
  }),
}

const DetailSection = function DetailSection({
  className,
  isReversed,
  isOverlappingPreviousSection,
  imgBuilder,
  titleBuilder,
  descriptionBuilder,
  buttonBuilder,
  bottomBuilder,
}) {
  const controls = useAnimation()
  const { ref, inView } = useInView({ threshold: 0.5 })

  useEffect(() => {
    if (inView) {
      controls.start('start')
    }
  }, [controls, inView])

  return (
    <section
      ref={ref}
      className={clsx('container mx-auto flex flex-col', className)}
    >
      <div
        className={clsx(
          'flex flex-col items-stretch lg:items-center',
          isReversed ? 'md:flex-row-reverse' : 'md:flex-row'
        )}
      >
        <motion.div
          initial="initial"
          animate={controls}
          variants={variants}
          className="mx-4 md:w-3/5 md:mx-0"
        >
          {imgBuilder != null && imgBuilder('w-full h-full')}
        </motion.div>
        <motion.div
          custom={1}
          initial="initial"
          animate={controls}
          variants={variants}
          className={clsx(
            'p-8 flex flex-col items-start md:w-2/5',
            isOverlappingPreviousSection && 'md:pt-20'
          )}
        >
          {titleBuilder != null && titleBuilder()}
          {descriptionBuilder != null && descriptionBuilder()}
          {buttonBuilder != null && buttonBuilder()}
        </motion.div>
      </div>
      <motion.div
        custom={2}
        initial="initial"
        animate={controls}
        variants={variants}
      >
        {bottomBuilder != null && bottomBuilder()}
      </motion.div>
    </section>
  )
}

export default DetailSection
