import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import clsx from 'clsx'
import getFluidImg from '../../utils/getFluidImg'
import PostListItem from '../shared/PostListItem'

function PostListingSection({ posts, className }) {
  return (
    <section
      className={clsx('container mx-auto flex flex-col posts-list px-4 md:px-0', className)}
    >
      {posts.map(post => {
        const { id, title, date, image, description, button } = post

        return (
          <PostListItem
            key={id}
            description={description}
            image={image}
            title={title}
            date={date}
            button={button}
          />
        )
      })}
    </section>
  )
}

export default function ArtGalleryPostsSlice() {
  const data = useStaticQuery(graphql`
    {
      posts: allPrismicArtGalleryPost(
        sort: { fields: data___date, order: DESC }
      ) {
        edges {
          node {
            id
            data {
              blog_title {
                text
              }
              date(formatString: "MMMM D, YYYY")
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              short_description {
                text
              }
              button_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
            }
          }
        }
      }
    }
  `)

  const posts = data?.posts?.edges ?? []

  const mappedPosts = posts.map(rawPost => {
    const node = rawPost?.node
    const id = node?.id
    const title = node?.data?.blog_title?.text
    const date = node?.data?.date
    const rawImage = node?.data?.image
    const description = node?.data?.short_description?.text
    const buttonText = node?.data?.button_text?.text
    const buttonLink = node?.data?.button_link

    const image = {
      alt: rawImage?.alt,
      fluidImage: getFluidImg(rawImage),
    }

    return {
      id,
      title,
      date,
      image,
      description,
      button: {
        text: buttonText,
        link: buttonLink,
      },
    }
  })

  return <PostListingSection posts={mappedPosts} />
}
